import Swiper, { Navigation, Pagination } from 'swiper';


if(window.innerWidth <= 1169) {
  function anyHover(buttons) {
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        button.classList.add('hover');

        setTimeout(() => {
          button.classList.remove('hover');
        }, 300)
      })
    });
  }

  anyHover(document.querySelectorAll('button'));
}

const vacancySlider = new Swiper('.vacancy__slider', {
  modules: [Pagination, Navigation],

  spaceBetween: 20,
  slidesPerView: 1,
  loop: true,
  speed: 700,

  pagination: {
    el: '.vacancy__slider-pagination',
    type: 'bullets',
  },
  navigation: {
    nextEl: '.vacancy__slider-arrow',
  },
});

const reviewsSlider = new Swiper('.reviews__slider', {
  modules: [Navigation],

  spaceBetween: 20,
  loop: true,
  speed: 700,

  navigation: {
    nextEl: '.reviews__slider-arrow',
  },

  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    767: {
      slidesPerView: 2,
    },
    1169: {
      slidesPerView: 3,
      initialSlide: 2,
      centeredSlides: true,
    }
  }
});
